import { WidgetPreset } from '@wix/platform-editor-sdk';

export const FastGalleryWidgetPresets: WidgetPreset[] = [
  {
    name: 'Design Preset 1',
    id: 'variants-lgnn37aw',
    src: 'https://i.pinimg.com/474x/50/86/1c/50861ccde3632eb03c58d6ab085564f3.jpg',
  },
  {
    name: 'Design Preset 2',
    id: 'variants-lyfhynz8',
    src: 'https://i.pinimg.com/474x/50/86/1c/50861ccde3632eb03c58d6ab085564f3.jpg',
  },
  {
    name: 'Design Preset 3',
    id: 'variants-lyx00yva',
    src: 'https://i.pinimg.com/474x/50/86/1c/50861ccde3632eb03c58d6ab085564f3.jpg',
  },
];
