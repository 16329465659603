import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { handleFirstInstall } from './first-install/handleFirstInstall';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  await editorSDK.addEventListener('componentAddedToStage', async (event) => {
    console.log('componentAddedToStage ==>');
  });
  await editorSDK.addEventListener('presetChanged', async ({ detail }) => {
    // temp fix for gallery id override removal
    const { componentRef } = detail;
    const controllerRef = (
      await editorSDK.components.getChildren('token', { componentRef })
    )[0];
    const { galleryId } = (
      await editorSDK.controllers.getData('token', {
        controllerRef,
      })
    )?.config;

    // Remove all overrides remove gallery id
    await editorSDK.components.refComponents.removeOverrides('', {
      componentRef,
    });
    await editorSDK.document.controllers.saveConfiguration('token', {
      controllerRef,
      config: {
        galleryId,
      },
    });
  });
  if (options.firstInstall) {
    handleFirstInstall(editorSDK, appDefId, options, flowAPI);
  }
};
