import { WidgetBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetBuilder';
import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { setWidgetPresetPanel } from '../../utils/manifestUtils';
import { FastGalleryWidgetPresets } from './config/constants';

const setDesktopGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Manage media'),
      onClick: async ({ detail }) => {
        const { componentRef } = detail;
        const galleryId = (
          await editorSDK.document.controllers.getData('', {
            controllerRef: componentRef,
          })
        ).config.galleryId;
        editorSDK.editor.openModalPanel('', {
          title: 'Organized Media Panel',
          url: getPanelUrl('fastGalleryWidget', 'FastGalleryOrganizeMedia'),
          height: '90%' as any,
          width: '90%' as any,
          initialData: {
            componentRef,
            galleryId,
          },
          centered: true,
        });
      },
    })
    .set('mainAction2', {
      label: t('Change Preset'),
      onClick: async ({ detail }) => {
        const { componentRef } = detail;
        editorSDK.editor.openModalPanel('', {
          url: getPanelUrl('fastGalleryWidget', 'FastGalleryPresetsPanel'),
          height: '90%' as any,
          width: '90%' as any,
          initialData: {
            componentRef,
          },
          shouldHideHeader: true,
          centered: true,
        });
      },
    })
    .set('design', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('settings', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('layout', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('widgetPlugins', {
      behavior: 'HIDE',
    });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  setDesktopGFPP(widgetBuilder, editorSDK, flowAPI);

  widgetBuilder.set({
    displayName: 'Fast Gallery',
    nickname: 'fastGalleryWidget',
  });
  setWidgetPresetPanel({
    widgetBuilder,
    PresetsArray: FastGalleryWidgetPresets,
  });
};
