import { WidgetPreset } from '@wix/platform-editor-sdk';
import { WidgetBuilder } from '@wix/yoshi-flow-editor';

export const setWidgetPresetPanel = ({
  widgetBuilder,
  PresetsArray,
}: {
  widgetBuilder: WidgetBuilder;
  PresetsArray: WidgetPreset[];
}) => {
  widgetBuilder.configureWidgetPresets((widgetPresetsBuilder) => {
    widgetPresetsBuilder.setPresets(PresetsArray);
  });
};
