import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

const presets = [
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7g',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7g1',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7g2',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7g3',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7h',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7h2',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7h1',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7h3',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7h4',
  },
  {
    src: 'https://www.reginapolice.ca/wp-content/uploads/placeholder-9.png',
    id: 'variants-m1eyuv7h5',
  },
];
export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget ExpandModeMainWidget',
    nickname: 'ExpandModeMainWidgetWidget',
  });

  widgetBuilder.behavior().set({
    resizable: false,
  });
  widgetBuilder.configureWidgetPresets((widgetPresetsBuilder) => {
    widgetPresetsBuilder.setPresets(presets);
  });
};
